.h-wrapper{
    background: var(--black);
    color: white;
}

.h-container{
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: var(--secondary);

}

.h-menu{
    gap: 2rem;
}

/* responsive sytyling  */

.menu-icon{
    display: none;
}


@media (max-width : 768px) {
    .menu-icon{
        display: block;
    }
}

/* .h-menu{
    color: var(--black);
    position: absolute;
    top: 3rem;
    right: 4rem;
    background: white;
    flex-direction: column;
    font-weight: 500;
    gap: 2rem;
    padding: 3rem;
    border-radius: 10px;
    align-items: flex-start;

} */


