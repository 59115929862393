.f-container{
    justify-content: space-between;
}


.f-left{
    gap: 1rem;
}


.f-menu{
    gap: 1.5rem;
    margin-top: 1.5rem;
    font-style: 500px;
}